import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'

import pic11 from '../../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Scopo Del Progetto </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    <p> L'obiettivo principale del nostro progetto è quello di promuovere
l’educazione nello sport e attaverso lo sport con un’attenzione particolare
allo sviluppo delle competenze degli insegnanti che lavorano nel campo
dello sport integrato in generale e nella danza integrata in particolare.
<br/><br/> Il nostro focus è direttamente correlato all&#39;inclusione sociale e alle pari
opportunità nello sport e parte delle nostre attività locali si focalizzeranno
su questo argomento.

<br/><br/> Il fine ultimo del progetto è promuovere e sostenere gli strumenti di
apprendimento reciproco, la ricerca interdisciplinare mirata ad un quadro
analitico basato sull'evidenza e lo scambio di conoscenze e buone pratiche
nel campo della danza e dello sport integrato. Tutto questo attraverso

seminari e riunioni di gruppi di esperti su questioni relative alla danza e
all'inclusione sociale, garantendo che i risultati di tali attività siano diffusi e
connessi alla pratica in ciascuno dei Paesi partecipanti.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic